import "./Crousal.css"

export default function Crousal()
{
    return(
        <>
            {/* ======Crousal===== */}

<section id="testimonials">
 {/* <!-- Crouselcode --> */}
 <div className="intro">
     {/* <p>
        <em>A firm of Chartered Accountants & Corporate Lawyers providing
         professional services to clients.</em>
          </p> */}
 </div>
  

 {/* <!-- end crousel -->     */}
</section>
        
        </>
    );
}